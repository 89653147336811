import React, {Component} from 'react'
import clip from './assets/videos/sixth-mill-race-park-observation-tower.mp4';
import first from './assets/images/sixth_lookout_tower1.jpg';
import second from './assets/images/sixth_lookout_tower2.png';
import third from './assets/images/sixth_lookout_tower3.jpg';
import fourth from './Columbus.jpg';
import Chocolat from 'chocolat';
import { Link, NavLink } from 'react-router-dom';
import About from "./assets/components/aboutsection";
import Footer from "./assets/components/footer";
import videoposter from './assets/images/videoposter.jpg';
import LazyLoad from "vanilla-lazyload";


function lightbox(){ 
document.addEventListener("DOMContentLoaded", function(event) {
        Chocolat(document.querySelectorAll('.chocolat-parent .chocolat-image'))
      })
}

lightbox();


class sixth_lookout_tower extends Component {
  render() {
    return (
      <div>

        <h1 className="main-title"><Link to="/">Columbus</Link></h1>
        <div className="wrapper">

       <video autoPlay loop muted className="wrapper__video" data-src={clip} poster={videoposter}>
          <source src={clip} type="video/mp4">
          </source>
       </video>
       <div className="page-content">
         <div className="content-wrapper">

         <h1 className="page-heading">Mill Race Park Observation Tower</h1>
         <p>This tower was designed to give Columbus residents a bird's eye view of the city.
         </p>

       </div>
       <h3>1990, Stanley Saitowitz</h3>
       <h3>50 Carl Miske Drive</h3>
       </div>

        </div>

        <div className="gallery">

          <div className="heading">
            <h1>Gallery</h1>
          </div>

              <div className="image-container">
                  <a className="chocolat-image" href={first} title="Observation Tower - http://www.saitowitz.com/work/miller-race-park/">
                      <img src={first} />
                  </a>
                  <a className="chocolat-image" href={third} title="Observation Tower design by Stanley Saitowitz - http://www.saitowitz.com/work/miller-race-park/">
                     <img src={third} />
                  </a>
                  <a className="chocolat-image" href={second} title="Columbus Screenshot - You can the see the drawing of what Jin and Casey think might be the tower">
                     <img src={second} />
                  </a>
                  <a className="chocolat-image" href={fourth} title="Columbus promotional still">
                     <img src={fourth} />
                  </a>
              </div>

        </div>
        <About />

          <Footer />
          </div>
    )
  }
}

export default sixth_lookout_tower;
