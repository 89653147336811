import React, {Component} from 'react'
import clip from './assets/videos/tenth_southside_school.mp4';
import first from './assets/images/tenth_southside_school1.jpg';
import second from './assets/images/tenth_southside_school2.jpg';
import third from './assets/images/tenth_southside_school3.jpg';
import fourth from './assets/images/tenth_southside_school4.jpg';
import fifth from './assets/images/tenth_southside_school5.png';
import sixth from './assets/images/tenth_southside_school6.png';
import Chocolat from 'chocolat';
import { Link, NavLink } from 'react-router-dom'
import About from "./assets/components/aboutsection";
import Footer from "./assets/components/footer";
import videoposter from './assets/images/videoposter.jpg';
import LazyLoad from "vanilla-lazyload";

function lightbox(){ 
document.addEventListener("DOMContentLoaded", function(event) {
        Chocolat(document.querySelectorAll('.chocolat-parent .chocolat-image'))
      })
}

lightbox();


class tenth_southside_school extends Component {
  render() {
    return (
      <div>

        <h1 className="main-title"><Link to="/">Columbus</Link></h1>
        <div className="wrapper">

       <video autoPlay loop muted className="wrapper__video" data-src={clip} poster={videoposter}>
          <source src={clip} type="video/mp4">
          </source>
       </video>
       <div className="page-content">
         <div className="content-wrapper">

         <h1 className="page-heading">Southside Elementary School</h1>
         <p> This brutalist two-story contrete building was designed in 1969 by Eliot Noyes as
         Southside Junior High before being converted into an elementary school in 1983. Despite the haunting
        fortress like exerior, the inside features a two-story common area with a skylight and a large mural by
        Ivan Chermayeff on the stairwells.
        <br />
        <br />
        This  is where Casey went to school.
         </p>

       </div>
       <h3>1969, Eliot Noyes</h3>
       <h3>1320 W 200 S</h3>
       </div>

        </div>

        <div className="gallery">

          <div className="heading">
            <h1>Gallery</h1>
          </div>

              <div className="image-container">
                  <a className="chocolat-image" href={first} title="Southside Elementary School, 1969 - https://www.archinform.net/projekte/5748.htm">
                      <img src={first} />
                  </a>
                  <a className="chocolat-image" href={fourth} title="Southside Elementary Schoo, 1969 - https://www.instagram.com/p/BCmMkSySxHP/?taken-by=hadleyfruits">
                     <img src={fourth} />
                  </a>
                  <a className="chocolat-image" href={third} title="Photo by Ricky Berkey - http://52weeks.rickyberkey.org/2011/04/10/week-15/">
                     <img src={third} />
                  </a>
                  <a className="chocolat-image" href={second} title="Reddit - https://www.reddit.com/r/brutalism/comments/cmfgu4/southside_elementary_school_columbus_indiana_usa/">
                     <img src={second} />
                  </a>
                  <a className="chocolat-image" href={fifth} title="Columbus Screenshot">
                     <img src={fifth} />
                  </a>
                  <a className="chocolat-image" href={sixth} title="Columbus Screenshot - Casey and Jin sneak into the school">
                     <img src={sixth} />
                  </a>
              </div>

        </div>

          <About />
          <Footer />

          </div>
    )
  }
}

export default tenth_southside_school;
