import React, {Component, useEffect} from 'react'
import { Link, NavLink } from 'react-router-dom'
import background from './Columbus.jpg';
import clip from './assets/videos/about.mp4';
import postertop from './assets/images/poster-top.jpeg';
import posterbottom from './assets/images/poster-bottom.jpeg';
import { motion } from 'framer-motion'
import Footer from "./assets/components/footer";

const transition = { duration: 1.4, ease: [0.6, 0.5, 0.5, 0.9] };

const imageDetails = { width: "100%", height: "200px" }

class About extends Component {
  render() {
    return (
      <motion.div 
      initial={{y: -20}}
      className="About" >
         <div className="about-function">
         useEffect(() => {
        window.scrollTo(0, 0)
          }, [])</div>

        <h1 className="main-title"><Link to="/">Columbus</Link></h1>
         <motion.div 
          initial={{ y: 0, width: imageDetails.width, height: imageDetails.height }}
          className="About"
          animate={{ 
            y: 0, 
            width: "100%",
            height: "600px",
            transition: {delay: 1, ...transition}
            }}
          

        className="parallax"></motion.div>
        <motion.div className="content"
          initial={{ opacity: 0 }}
          animate={{
                opacity: 1,
                transition: { delay: 2, ...transition },
              }}
          >
          <div className="text about">
            <h1>About</h1>
            <div className="about-paragraph">
                <p>This project was made because of my love for Columbus (2017) - debut feature film by Kogonada starring John Cho and Haley Lu Richardson. I made this map for myself and fellow film/architecture fans who appreciate the beautiful spaces in Columbus and want to learn more about it.</p>
                <br />
                <p>-Wolfgang</p>
            </div>
          </div>
        </motion.div>

        <motion.div 
          initial={{ opacity: 0 }}
          animate={{
                opacity: 1,
                transition: { delay: 2, ...transition },
              }}  
          className="page-wrapper">
          <img src={postertop} alt="Columbus poster heading" className="poster"/>

          <div className="wrapper">
            <video autoPlay loop muted className="about-video">
              <source src={clip} type="video/mp4">
              </source>
            </video>
          </div>

          <img src={postertop} alt="Columbus poster heading" className="poster"/>
        </motion.div>

          <Footer />
      </motion.div>
    )
  }
}

export default About;
