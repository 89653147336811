import React, {Component} from 'react'
import clip from './assets/videos/seventeenth_henry_social_club.mp4';
import first from './assets/images/seventeenth_henry_social_club1.jpg';
import second from './assets/images/seventeenth_henry_social_club2.jpg';
import third from './assets/images/seventeenth_henry_social_club3.jpg';
import Chocolat from 'chocolat';
import { Link, NavLink } from 'react-router-dom'
import About from "./assets/components/aboutsection";
import Footer from "./assets/components/footer";
import videoposter from './assets/images/videoposter.jpg';
import LazyLoad from "vanilla-lazyload";

function lightbox(){ 
document.addEventListener("DOMContentLoaded", function(event) {
        Chocolat(document.querySelectorAll('.chocolat-parent .chocolat-image'))
      })
}

lightbox();


class seventeenth_henry_social_club extends Component {
  render() {
    return (
      <div>

        <h1 className="main-title"><Link to="/">Columbus</Link></h1>
        <div className="wrapper">

       <video autoPlay loop muted className="wrapper__video" data-src={clip} poster={videoposter}>
          <source src={clip} type="video/mp4">
          </source>
       </video>
       <div className="page-content">
         <div className="content-wrapper">

         <h1 className="page-heading">Henry Social Club</h1>
         <p>
         There is nothing particularly unique about this sleek restuarant in the heart of Columbus. 
         I imagine it was chosen because of its intimate ambiance and proximity to where Jin was staying, The Inn. 
         </p>

       </div>
       <h3>2014, Nikki Sutton</h3>
       <h3>423 Washington Street</h3>
       </div>

        </div>

        <div className="gallery">

          <div className="heading">
            <h1>Gallery</h1>
          </div>

              <div className="image-container">
                  <a className="chocolat-image" href={first} title="Interior - https://visitindiana.com/food-and-drink/52533-henry-social-club">
                      <img src={first} />
                  </a>
                  <a className="chocolat-image" href={second} title="Promtional Columbus still">
                     <img src={second} />
                  </a>
                  <a className="chocolat-image" href={third} title="The crew of COLUMBUS the movie at Henry Social Club - https://www.pinterest.co.uk/pin/405535141441292931/ ">
                     <img src={third} />
                  </a>
              </div>

        </div>

          <About />
          <Footer />
          </div>
    )
  }
}

export default seventeenth_henry_social_club;
