import React, {Component} from 'react'
import clip from './assets/videos/fourth_first_bank.mp4';
import first from './assets/images/fourth_first_bank1.jpg';
import second from './assets/images/fourth_first_bank2.jpg';
import third from './assets/images/fourth_first_bank3.jpg';
import fourth from './assets/images/fourth_first_bank4.jpg';
import Chocolat from 'chocolat';
import { Link, NavLink } from 'react-router-dom'
import About from "./assets/components/aboutsection";
import Footer from "./assets/components/footer";
import videoposter from './assets/images/videoposter.jpg';
import LazyLoad from "vanilla-lazyload";

function lightbox(){ 
document.addEventListener("DOMContentLoaded", function(event) {
        Chocolat(document.querySelectorAll('.chocolat-parent .chocolat-image'))
      })
}

lightbox();


class fourth_first_bank extends Component {
  render() {
    return (
      <div>

        <h1 className="main-title"><Link to="/">Columbus</Link></h1>
        <div className="wrapper">

       <video autoPlay loop muted className="wrapper__video" data-src={clip} poster={videoposter}>
          <source src={clip} type="video/mp4">
          </source>
       </video>
       <div className="page-content">
         <div className="content-wrapper">

         <h1 className="page-heading">First Financial Bank</h1>
         <p>Formerly called Irwin Union Bank. This is number 3 on Casey's list because it began her 
         interest in architecture. It was Deborah Berke who Casey met when she was giving a talk in Indiana
         about <Link to="/miller-house">The Miller House</Link> and encouraged her to apply to The University of New Haven.
         </p>

       </div>
       <h3>2006, Deborah Berke</h3>
       <h3>707 Creekview Drive</h3>
       </div>

        </div>

        <div className="gallery">

          <div className="heading">
            <h1>Gallery</h1>
          </div>

              <div className="image-container">
                  <a className="chocolat-image" href={first} title="https://dberke.com/Irwin-Union-Bank">
                      <img src={first} />
                  </a>
                  <a className="chocolat-image" href={second} title="https://www.flickr.com/photos/h_ssan/20830461944">
                     <img src={second} />
                  </a>
                  <a className="chocolat-image" href={third} title="http://ndagallery.cooperhewitt.org/gallery/51086391/Irwin-Union-Bank">
                     <img src={third} />
                  </a>
                  <a className="chocolat-image" href={fourth} title="http://ndagallery.cooperhewitt.org/gallery/51086391/Irwin-Union-Bank">
                     <img src={fourth} />
                  </a>
              </div>

        </div>

        <About />

          <Footer />
          </div>
    )
  }
}

export default fourth_first_bank;
