import React, {Component} from 'react'
import clip from './assets/videos/second_irwin_conference_center.mp4';
import first from './assets/images/second_irwin_conference_center1.jpeg';
import second from './assets/images/second_irwin_conference_center2.png';
import Chocolat from 'chocolat';
import { Link, NavLink } from 'react-router-dom'
import About from "./assets/components/aboutsection";
import Footer from "./assets/components/footer";
import videoposter from './assets/images/videoposter.jpg';
import LazyLoad from "vanilla-lazyload";

function lightbox(){ 
document.addEventListener("DOMContentLoaded", function(event) {
        Chocolat(document.querySelectorAll('.chocolat-parent .chocolat-image'))
      })
}

lightbox();


class second_irwin_conference_center extends Component {
  render() {
    return (
      <div>

        <h1 className="main-title"><Link to="/">Columbus</Link></h1>
        <div className="wrapper">

       <video autoPlay loop muted className="wrapper__video" data-src={clip} poster={videoposter}>
          <source src={clip} type="video/mp4">
          </source>
       </video>
       <div className="page-content">
         <div className="content-wrapper">

         <h1 className="page-heading">Irwin Conference Center</h1>
         <p>It was one of the first modernist banks in America (previously called Irwin Union Bank). It's all
         glass walls were designed to be inviting and transparent as opposed to a villainous fortress as 
         banks were often previously designed. It is the 21st National Historic Landmark in Indiana.
         This is Casey's second favourite building.
         </p>

       </div>
       <h3>1947, Eero Saarinen</h3>
       <h3>500 Washington Street</h3>
       </div>

        </div>

        <div className="gallery">

          <div className="heading">
            <h1>Gallery</h1>
          </div>

              <div className="chocolat-parent">
                  <a className="chocolat-image" href={first} title="Irwin Conference Center - https://alchetron.com/Irwin-Conference-Center">
                      <img src={first} />
                  </a>
                  <a className="chocolat-image" href={second} title="Columbus Screenshot">
                     <img src={second} />
                  </a>
              </div>

        </div>
        <About />

          <Footer />
          </div>
    )
  }
}

export default second_irwin_conference_center;
