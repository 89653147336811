import React, {Component} from 'react'
import clip from './assets/videos/twelveth_miller_house.mp4';
import first from './assets/images/twelveth_miller_house1.jpg';
import second from './assets/images/twelveth_miller_house2.jpg';
import third from './assets/images/twelveth_miller_house3.png';
import fourth from './assets/images/twelveth_miller_house4.png';
import fifth from './assets/images/twelveth_miller_house5.png';
import sixth from './assets/images/twelveth_miller_house6.png';
import seventh from './assets/images/twelveth_miller_house7.jpg';
import eighth from './assets/images/twelveth_miller_house8.png';
import ninth from './assets/images/twelveth_miller_house9.png';
import tenth from './assets/images/twelveth_miller_house10.jpg';
import Chocolat from 'chocolat';
import { Link, NavLink } from 'react-router-dom'
import About from "./assets/components/aboutsection";
import Footer from "./assets/components/footer";
import videoposter from './assets/images/videoposter.jpg';
import LazyLoad from "vanilla-lazyload";

function lightbox(){ 
document.addEventListener("DOMContentLoaded", function(event) {
        Chocolat(document.querySelectorAll('.chocolat-parent .chocolat-image'))
      })
}

lightbox();


class twelveth_miller_house extends Component {
  render() {
    return (
      <div>

        <h1 className="main-title"><Link to="/">Columbus</Link></h1>
        <div className="wrapper">

       <video autoPlay loop muted className="wrapper__video" data-src={clip} poster={videoposter}>
          <source src={clip} type="video/mp4">
          </source>
       </video>
       <div className="page-content">
         <div className="content-wrapper">

         <h1 className="page-heading">The Miller House and Garden</h1>
         <p>The most recognisable building featured in Columbus is The Miller House and
         Garden designed by Eero Saarinen in 1957. It's a staple of midcentury modern design 
         and, as such, was named a U.S. National Historic Landmark in 2000. The interior design, by
         Alexander Girard, is just as important as the architecture because it features the
         world's first living room conversation pit. It sits on 13.5 acres of land. 
         The opening scene of the film was shot here.
         </p>

       </div>
       <h3>1957, Eero Saarinen</h3>
       <h3>506 5th St</h3>
       </div>

        </div>

        <div className="gallery">

          <div className="heading">
            <h1>Gallery</h1>
          </div>

              <div className="image-container">
                  <a className="chocolat-image" href={first} title="The Miller House by Ezra Stoller - https://www.curbed.com/2017/3/23/15026328/sunken-living-room-conversation-pit">
                      <img src={first} />
                  </a>
                  <a className="chocolat-image" href={second} title="The Miller House by Ezra Stoller - https://www.curbed.com/2017/3/23/15026328/sunken-living-room-conversation-pit">
                     <img src={second} />
                  </a>
                  <a className="chocolat-image" href={third} title="Columbus Screenshot">
                     <img src={third} />
                  </a>
                  <a className="chocolat-image" href={fourth} title="Columbus Screenshot">
                     <img src={fourth} />
                  </a>
                  <a className="chocolat-image" href={fifth} title="Columbus Screenshot">
                      <img src={fifth} />
                  </a>
                  <a className="chocolat-image" href={sixth} title="Columbus Screenshot">
                     <img src={sixth} />
                  </a>
                  <a className="chocolat-image" href={seventh} title="Columbus Promotional Still">
                     <img src={seventh} />
                  </a>
                  <a className="chocolat-image" href={eighth} title="Columbus Screenshot">
                     <img src={eighth} />
                  </a>
                  <a className="chocolat-image" href={ninth} title="Columbus Promotional Still">
                     <img src={ninth} />
                  </a>
                  <a className="chocolat-image" href={tenth} title="The Miller House by Leslie Williamson - https://www.dwell.com/home/miller-house-in-columbus-indiana-by-eero-saarinen-7565ad76">
                     <img src={tenth} />
                  </a>
              </div>

        </div>

         <About />
         <Footer />
          </div>
    )
  }
}

export default twelveth_miller_house;
