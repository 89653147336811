import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import ReactDOM from 'react-dom'
import About from './About'
import FirstChristianChurch from './FirstChristianChurch'
import './index.css'
import { CSSTransitionGroup } from 'react-transition-group'
import Logo from './assets/icons/icon.png'


const mapStyle = [
 {
   "elementType": "geometry",
   "stylers": [
     {
       "color": "#ebe3cd"
     }
   ]
 },
 {
   "elementType": "labels",
   "stylers": [
     {
       "visibility": "off"
     }
   ]
 },
 {
   "elementType": "labels.text.fill",
   "stylers": [
     {
       "color": "#523735"
     }
   ]
 },
 {
   "elementType": "labels.text.stroke",
   "stylers": [
     {
       "color": "#f5f1e6"
     }
   ]
 },
 {
   "featureType": "administrative",
   "elementType": "geometry",
   "stylers": [
     {
       "visibility": "off"
     }
   ]
 },
 {
   "featureType": "administrative",
   "elementType": "geometry.stroke",
   "stylers": [
     {
       "color": "#c9b2a6"
     }
   ]
 },
 {
   "featureType": "administrative.land_parcel",
   "stylers": [
     {
       "visibility": "off"
     }
   ]
 },
 {
   "featureType": "administrative.land_parcel",
   "elementType": "geometry.stroke",
   "stylers": [
     {
       "color": "#dcd2be"
     }
   ]
 },
 {
   "featureType": "administrative.land_parcel",
   "elementType": "labels.text.fill",
   "stylers": [
     {
       "color": "#ae9e90"
     }
   ]
 },
 {
   "featureType": "administrative.neighborhood",
   "stylers": [
     {
       "visibility": "off"
     }
   ]
 },
 {
   "featureType": "landscape.natural",
   "elementType": "geometry",
   "stylers": [
     {
       "color": "#dfd2ae"
     }
   ]
 },
 {
   "featureType": "poi",
   "stylers": [
     {
       "visibility": "off"
     }
   ]
 },
 {
   "featureType": "poi",
   "elementType": "geometry",
   "stylers": [
     {
       "color": "#dfd2ae"
     }
   ]
 },
 {
   "featureType": "poi",
   "elementType": "labels.text.fill",
   "stylers": [
     {
       "color": "#93817c"
     }
   ]
 },
 {
   "featureType": "poi.park",
   "elementType": "geometry.fill",
   "stylers": [
     {
       "color": "#a5b076"
     }
   ]
 },
 {
   "featureType": "poi.park",
   "elementType": "labels.text.fill",
   "stylers": [
     {
       "color": "#447530"
     }
   ]
 },
 {
   "featureType": "road",
   "stylers": [
     {
       "visibility": "off"
     }
   ]
 },
 {
   "featureType": "road",
   "elementType": "geometry",
   "stylers": [
     {
       "color": "#f5f1e6"
     }
   ]
 },
 {
   "featureType": "road",
   "elementType": "labels.icon",
   "stylers": [
     {
       "visibility": "off"
     }
   ]
 },
 {
   "featureType": "road.arterial",
   "elementType": "geometry",
   "stylers": [
     {
       "color": "#fdfcf8"
     }
   ]
 },
 {
   "featureType": "road.highway",
   "elementType": "geometry",
   "stylers": [
     {
       "color": "#f8c967"
     }
   ]
 },
 {
   "featureType": "road.highway",
   "elementType": "geometry.stroke",
   "stylers": [
     {
       "color": "#e9bc62"
     }
   ]
 },
 {
   "featureType": "road.highway.controlled_access",
   "elementType": "geometry",
   "stylers": [
     {
       "color": "#e98d58"
     }
   ]
 },
 {
   "featureType": "road.highway.controlled_access",
   "elementType": "geometry.stroke",
   "stylers": [
     {
       "color": "#db8555"
     }
   ]
 },
 {
   "featureType": "road.local",
   "elementType": "labels.text.fill",
   "stylers": [
     {
       "color": "#806b63"
     }
   ]
 },
 {
   "featureType": "transit",
   "stylers": [
     {
       "visibility": "off"
     }
   ]
 },
 {
   "featureType": "transit.line",
   "elementType": "geometry",
   "stylers": [
     {
       "color": "#dfd2ae"
     }
   ]
 },
 {
   "featureType": "transit.line",
   "elementType": "geometry.fill",
   "stylers": [
     {
       "weight": 6
     }
   ]
 },
 {
   "featureType": "transit.line",
   "elementType": "labels.text.fill",
   "stylers": [
     {
       "color": "#8f7d77"
     }
   ]
 },
 {
   "featureType": "transit.line",
   "elementType": "labels.text.stroke",
   "stylers": [
     {
       "color": "#ebe3cd"
     }
   ]
 },
 {
   "featureType": "transit.station",
   "elementType": "geometry",
   "stylers": [
     {
       "color": "#dfd2ae"
     }
   ]
 },
 {
   "featureType": "water",
   "elementType": "geometry.fill",
   "stylers": [
     {
       "color": "#b9d3c2"
     }
   ]
 },
 {
   "featureType": "water",
   "elementType": "labels.text.fill",
   "stylers": [
     {
       "color": "#92998d"
     }
   ]
 }
 ]

 const logo = require('./assets/icons/icon.png');

export class MapContainer extends Component {
  _mapLoaded(mapProps, map) {
      map.setOptions({
         styles: mapStyle,
         disableDefaultUI: true
      })
   }

   onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props.data,
            activeMarker: marker,
            showingInfoWindow: true,
            redirect: true
        })
        
        //this.props.history.push(marker.link);
       window.location.href = marker.link;
    }

  render() {
    return (

    <div>
    <h1 className="main-title">Columbus</h1>
    <Map google={this.props.google}
      style={{width: '100%', height: '100%', position: 'relative'}}
      className={'columbus'}
      zoom={13.4}
      initialCenter={{lat: 39.202945, lng: -85.906393}}
      onReady={(mapProps, map) => this._mapLoaded(mapProps, map)}>

    <Marker
      name={'first_christian_church'}
      position={{lat: 39.2028786, lng: -85.9212517}} 
      onClick={this.onMarkerClick}
      link={'first-christian-church'}
      icon={{
      	url: logo
      }}
      data={"About"}
      label={{
        text: "1",
        fontFamily: "Futura",
        fontSize: "14px",
        color: 'white',
        fontWeight: 'bold'
      }} />

    <Marker
      name={'second_irwin_conference_center'}
      link={'irwin-conference-center'}
      onClick={this.onMarkerClick}
      icon={{
      	url: logo
      }}
      position={{lat: 39.203914, lng: -85.921526}} 
      label={{
        text: "2",
        fontFamily: "Futura",
        fontSize: "14px",
        color: 'white',
        fontWeight: 'bold'
      }} />

    <Marker
      name={'third_quinco_mental'}
      link={'quinco-mental'}
      onClick={this.onMarkerClick}
      position={{lat: 39.2228313, lng: -85.899795}} 
      icon={{
      	url: logo
      }}
      label={{
        text: "3",
        fontFamily: "Futura",
        fontSize: "14px",
        color: 'white',
        fontWeight: 'bold'
      }} />

    <Marker
      name={'fourth_first_bank'}
      link={'first-financial-bank'}
      onClick={this.onMarkerClick}
      position={{lat: 39.2068862, lng:-85.8840864}} 
      icon={{
      	url: logo
      }}
      label={{
        text: "4",
        fontFamily: "Futura",
        fontSize: "14px",
        color: 'white',
        fontWeight: 'bold'
      }} />

    <Marker
      name={'fifth_north_church'}
      link={'north-christian-church'}
      onClick={this.onMarkerClick}
      position={{lat:39.2068706 , lng: -85.8835736 }} 
      icon={{
      	url: logo
      }}
      label={{
        text: "5",
        fontFamily: "Futura",
        fontSize: "14px",
        color: 'white',
        fontWeight: 'bold'
      }} />

    <Marker
      name={'sixth_lookout_tower'}
      link={'mill-race-park-observation-tower'}
      onClick={this.onMarkerClick}
      position={{lat: 39.2052932 , lng: -85.9273122}} 
      icon={{
      	url: logo
      }}
      label={{
        text: "6",
        fontFamily: "Futura",
        fontSize: "14px",
        color: 'white',
        fontWeight: 'bold'
      }} />

    <Marker
      name={'seventh_covered_bridge'}
      link={'covered-bridge-and-pond'}
      onClick={this.onMarkerClick}
      position={{lat: 39.2063534, lng: -85.9291901}} 
      icon={{
      	url: logo
      }}
      label={{
        text: "7",
        fontFamily: "Futura",
        fontSize: "14px",
        color: 'white',
        fontWeight: 'bold'
      }} />

    <Marker
      name={'eighth_veterans_memorial'}
      link={'veterans-memorial'}
      onClick={this.onMarkerClick}
      position={{lat: 39.2010421, lng: -85.9214455}} 
      icon={{
      	url: logo
      }}
      label={{
        text: "8",
        fontFamily: "Futura",
        fontSize: "14px",
        color: 'white',
        fontWeight: 'bold'
      }} />

    <Marker
      name={'ninth_newpaper_plant'}
      link={'newspaper-plant'}
      onClick={this.onMarkerClick}
      position={{lat: 39.1998963, lng: -85.9237792}} 
      icon={{
      	url: logo
      }}
      label={{
        text: "9",
        fontFamily: "Futura",
        fontSize: "14px",
        color: 'white',
        fontWeight: 'bold'
      }} />

    <Marker
      name={'tenth_southside_school'}
      link={'southside-elementary-school'}
      onClick={this.onMarkerClick}
      position={{lat: 39.1723393, lng: -85.9377205}} 
      icon={{
      	url: logo
      }}
      label={{
        text: "10",
        fontFamily: "Futura",
        fontSize: "14px",
        color: 'white',
        fontWeight: 'bold'
      }} />

    <Marker
      name={'eleventh_city_hall'}
      link={'columbus-city-hall'}
      onClick={this.onMarkerClick}
      position={{lat: 39.199942, lng: -85.9204163}} 
      icon={{
      	url: logo
      }}
      label={{
        text: "11",
        fontFamily: "Futura",
        fontSize: "14px",
        color: 'white',
        fontWeight: 'bold'
      }} />

    <Marker
      name={'twelveth_miller_house'}
      link={'miller-house'}
      onClick={this.onMarkerClick}
      position={{lat: 39.2037585, lng: -85.9209975}} 
      icon={{
      	url: logo
      }}
      label={{
        text: "12",
        fontFamily: "Futura",
        fontSize: "14px",
        color: 'white',
        fontWeight: 'bold'
      }} />

      <Marker
      name={'thirteenth_columbus_regional_hospital'}
      link={'columbus-regional-hospital'}
      onClick={this.onMarkerClick}
      position={{lat: 39.2164118, lng: -85.8942085}} 
      icon={{
      	url: logo
      }}
      label={{
        text: "13",
        fontFamily: "Futura",
        fontSize: "14px",
        color: 'white',
        fontWeight: 'bold'
      }} />

      <Marker
      name={'fourteenth_the_inn'}
      link={'the-inn'}
      onClick={this.onMarkerClick}
      position={{lat: 39.203891, lng: -85.9202147}} 
      icon={{
      	url: logo
      }}
      label={{
        text: "14",
        fontFamily: "Futura",
        fontSize: "14px",
        color: 'white',
        fontWeight: 'bold'
      }} />

      <Marker
      name={'fifteenth_cleo_rogers_memorial_library'}
      link={'cleo-rogers-memorial-library'}
      onClick={this.onMarkerClick}
      position={{lat: 39.2041629, lng: -85.9187672}} 
      icon={{
        url: logo
      }}
      label={{
        text: "15",
        fontFamily: "Futura",
        fontSize: "15px",
        color: 'white',
        fontWeight: 'bold'
      }} />

       <Marker
      name={'sixteenth_viewpoint_bookstore'}
      link={'viewpoint-bookstore'}
      onClick={this.onMarkerClick}
      position={{lat: 39.2045731, lng: -85.9213278}} 
      icon={{
        url: logo
      }}
      label={{
        text: "16",
        fontFamily: "Futura",
        fontSize: "15px",
        color: 'white',
        fontWeight: 'bold'
      }} />

      <Marker
      name={'seventeenth_henry_social_club'}
      link={'henry-social-club'}
      onClick={this.onMarkerClick}
      position={{lat: 39.2030598, lng: -85.9209488}} 
      icon={{
        url: logo
      }}
      label={{
        text: "17",
        fontFamily: "Futura",
        fontSize: "15px",
        color: 'white',
        fontWeight: 'bold'
      }} />

      <Marker
      name={'eighteenth_friendship_way'}
      link={'friendship-way'}
      onClick={this.onMarkerClick}
      position={{lat: 39.202966, lng: -85.9235498}} 
      icon={{
        url: logo
      }}
      label={{
        text: "18",
        fontFamily: "Futura",
        fontSize: "15px",
        color: 'white',
        fontWeight: 'bold'
      }} />

       <Marker
      name={'nineteenth_robert_n_stewart_bridge'}
      link={'robert-n-stewart-bridge'}
      onClick={this.onMarkerClick}
      position={{lat: 39.1984243, lng: -85.92497}} 
      icon={{
        url: logo
      }}
      label={{
        text: "19",
        fontFamily: "Futura",
        fontSize: "15px",
        color: 'white',
        fontWeight: 'bold'
      }} />

</Map>
</div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyATDxl73ANkp50hcFCUTZKfJPBQ8VZXbyI'
})(MapContainer);
