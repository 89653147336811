import React, {Component} from 'react'
import clip from './assets/videos/nineteenth_robert_n_stewart_bridge.mp4';
import first from './assets/images/nineteenth_robert_n_stewart_bridge1.jpg';
import second from './assets/images/nineteenth_robert_n_stewart_bridge2.jpg';
import third from './assets/images/nineteenth_robert_n_stewart_bridge3.png';
import fourth from './assets/images/nineteenth_robert_n_stewart_bridge4.png';
import Chocolat from 'chocolat';
import { Link, NavLink } from 'react-router-dom'
import About from "./assets/components/aboutsection";
import Footer from "./assets/components/footer";
import { AnimatePresence, motion } from "framer-motion";
import videoposter from './assets/images/videoposter.jpg';
import LazyLoad from "vanilla-lazyload";

function lightbox(){ 
document.addEventListener("DOMContentLoaded", function(event) {
        Chocolat(document.querySelectorAll('.chocolat-parent .chocolat-image'))
      })
}

lightbox();


const transition = { duration: 1.4, ease: [0.17, 0.67, 0.83, 0.67] };


class nineteenth_robert_n_stewart_bridge extends Component {
  render() {
    return (
      <div>

        <h1 className="main-title"><Link to="/">Columbus</Link></h1>
        <div className="wrapper">

       <video autoPlay loop muted className="wrapper__video" data-src={clip} poster={videoposter}>
          <source src={clip} type="video/mp4">
          </source>
       </video>
       <div className="page-content">
         <div className="content-wrapper">

         <h1 className="page-heading">Robert N. Stewart Bridge</h1>
         <p>
         The A shaped bridge is a staple of Indiana architecture offering
         a remarkable view of the city. Constructed of 40 cables that 
         lay out in a fan-like shape across the sides, you
         can see the
         <motion.a 
              animate={{
                transition: { delay: 2, ...transition },
              }}

         href="first-christian-church"> First Christian Church </motion.a>
         from the 465 feet long
         and 80 feet wide bridge.
         </p>

       </div>
       <h3>1999, J. Muller International</h3>
       <h3>State Road 46W</h3>
       </div>

        </div>

        <div className="gallery">

          <div className="heading">
            <h1>Gallery</h1>
          </div>

              <div className="image-container">
                  <a className="chocolat-image" href={first} title="Robert N. Stewart Bridge - https://www.loc.gov/item/2013650712/">
                      <img src={first} />
                  </a>
                  <a className="chocolat-image" href={second} title="Robert N. Stewart Bridge - http://www.embarch.com/columbus-indiana-robert-n-stewart-bridge">
                     <img src={second} />
                  </a>
                  <a className="chocolat-image" href={third} title="Columbus Screenshot">
                     <img src={third} />
                  </a>
                  <a className="chocolat-image" href={fourth} title="Columbus Screenshot">
                     <img src={fourth} />
                  </a>
              </div>

        </div>

          <About />
          <Footer />
          </div>
    )
  }
}

export default nineteenth_robert_n_stewart_bridge;
