import React, {Component} from 'react'
import clip from './assets/videos/fourteenth_the_inn.mp4';
import first from './assets/images/fourteenth_the_inn1.jpg';
import second from './assets/images/fourteenth_the_inn2.jpg';
import third from './assets/images/fourteenth_the_inn3.png';
import fourth from './assets/images/fourteenth_the_inn4.png';
import fifth from './assets/images/fourteenth_the_inn5.png';
import sixth from './assets/images/fourteenth_the_inn6.png';
import Chocolat from 'chocolat';
import { Link, NavLink } from 'react-router-dom'
import About from "./assets/components/aboutsection";
import Footer from "./assets/components/footer";
import videoposter from './assets/images/videoposter.jpg';
import LazyLoad from "vanilla-lazyload";

function lightbox(){ 
document.addEventListener("DOMContentLoaded", function(event) {
        Chocolat(document.querySelectorAll('.chocolat-parent .chocolat-image'))
      })
}

lightbox();


class fourteenth_the_inn extends Component {
  render() {
    return (
      <div>

        <h1 className="main-title"><Link to="/">Columbus</Link></h1>
        <div className="wrapper">

       <video autoPlay loop muted className="wrapper__video" data-src={clip} poster={videoposter}>
          <source src={clip} type="video/mp4">
          </source>
       </video>
       <div className="page-content">
         <div className="content-wrapper">

         <h1 className="page-heading">The Inn at Irwin Gardens</h1>
         <p>

         This two-acre Italianate style property features a gorgeous grounds with a maze based on the 
         Casa degli Innamorati in Pompeii, a swimming pool, multiple fountains, historic statues 
         and thriving Wisteria plants. 
         </p>

       </div>
       <h3>1864, Joseph I. Irwin</h3>
       <h3>608 5th Street </h3>
       </div>

        </div>

        <div className="gallery">

          <div className="heading">
            <h1>Gallery</h1>
          </div>

              <div className="image-container">
                  <a className="chocolat-image" href={first} title="The Inn at Irwin Gardens - https://columbus.in.us/inn-at-irwin-gardens/">
                      <img src={first} />
                  </a>
                  <a className="chocolat-image" href={second} title="The Inn at Irwin Gardens - https://globalphile.com/where-to-stay-in-columbus-indiana/">
                     <img src={second} />
                  </a>
                  <a className="chocolat-image" href={third} title="Columbus Screenshot">
                     <img src={third} />
                  </a>
                  <a className="chocolat-image" href={fourth} title="Columbus Screenshot">
                     <img src={fourth} />
                  </a>
                  <a className="chocolat-image" href={fifth} title="Columbus Screenshot">
                      <img src={fifth} />
                  </a>
                  <a className="chocolat-image" href={sixth} title="Columbus Screenshot">
                     <img src={sixth} />
                  </a>
              </div>

        </div>

          <About />
          <Footer />
          </div>
    )
  }
}

export default fourteenth_the_inn;
