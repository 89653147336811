import React, {Component} from 'react'
import clip from './assets/videos/first_christian_church.mp4';
import first from './assets/images/first_christian_church.jpg';
import second from './assets/images/first_christian_church2.png';
import Chocolat from 'chocolat';
import { Link, NavLink } from 'react-router-dom'
import About from "./assets/components/aboutsection";
import Footer from "./assets/components/footer";
import videoposter from './assets/images/videoposter.jpg';
import LazyLoad from "vanilla-lazyload";

function lightbox(){ 
document.addEventListener("DOMContentLoaded", function(event) {
        Chocolat(document.querySelectorAll('.chocolat-parent .chocolat-image'))
      })
}

lightbox();


class FirstChristianChurch extends Component {
  render() {
    return (
      <div>

        <h1 className="main-title"><Link to="/">Columbus</Link></h1>
        <div className="wrapper">

       <video autoPlay loop muted className="wrapper__video" data-src={clip} poster={videoposter}>
          <source src={clip} type="video/mp4">
          </source>
       </video>
       
       <div className="page-content">
         <div className="content-wrapper">

         <h1 className="page-heading">First Christian Church</h1>
         <p>This was the first contemporary building in Columbus, Indiana and also the film. It's one of the first modern contemporary architecture churches in the United States.
            It is designed by Finnish architect Eliel Saarinen who won the AIA Gold Medal in 1947.
         </p>

       </div>
       <h3>1942, Eleil Saarien</h3>
       <h3>531 5th Street</h3>
       </div>

        </div>

        <div className="gallery">

          <div className="heading">
            <h1>Gallery</h1>
          </div>

              <div className="image-container">
                  <a className="chocolat-image" href={first} title="First Christian Church - https://www.dezeen.com/2017/09/01/11-important-modernist-buildings-columbus-indiana/">
                      <img src={first} />
                  </a>
                  <a className="chocolat-image" href={second} title="Columbus Screenshot">
                     <img src={second} />
                  </a>
              </div>

        </div>
        <About />

          <Footer />
          </div>
    )
  }
}

export default FirstChristianChurch;
