import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom";
import App from './App'
import './index.css'
import './assets/fonts/FuturaPTBold.otf'
import './assets/fonts/FuturaPTBoldOblique.otf'
import './assets/fonts/FuturaPTLight.otf'
import './assets/fonts/FuturaPTDemiOblique.otf'

ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>, 
    document.getElementById('root')
)