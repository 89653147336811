import React, {Component} from 'react'
import clip from './assets/videos/fifth_north_church.mp4';
import first from './assets/images/fifth_north_church1.jpg';
import second from './assets/images/fifth_north_church2.jpg';
import third from './assets/images/fifth_north_church3.jpeg';
import fourth from './assets/images/fifth_north_church4.jpg';
import fifth from './assets/images/fifth_north_church5.png';
import sixth from './assets/images/fifth_north_church6.jpg';
import Chocolat from 'chocolat';
import { Link, NavLink } from 'react-router-dom'
import About from "./assets/components/aboutsection";
import { AnimatePresence, motion } from "framer-motion";
import Footer from "./assets/components/footer";
import videoposter from './assets/images/videoposter.jpg';
import LazyLoad from "vanilla-lazyload";

function lightbox(){ 
document.addEventListener("DOMContentLoaded", function(event) {
        Chocolat(document.querySelectorAll('.chocolat-parent .chocolat-image'))
      })
}

lightbox();

const transition = { duration: 1.4, ease: [0.17, 0.67, 0.83, 0.67] };

class fifth_north_church extends Component {
  render() {
    return (
      <div>

        <h1 className="main-title"><Link to="/">Columbus</Link></h1>
        <div className="wrapper">

       <video autoPlay loop muted className="wrapper__video" data-src={clip} poster={videoposter}>
          <source src={clip} type="video/mp4">
          </source>
       </video>
       <div className="page-content">
         <div className="content-wrapper">

         <h1 className="page-heading">North Christian Church</h1>
         <p>The aim was for Eero to design a more traditional chuch building because he felt churches
         had lost their grandiosity.
         The sanctuary of the church is on a higher level which architecturally resembles the spiritual 
         journey that takes place in church; one is trying to ascend closer to God. Eero's father, Eliel, designed the 
         <motion.a 
              animate={{
                transition: { delay: 2, ...transition },
              }}

         href="first-christian-church"> First Christian Church</motion.a>. Sadly, this building was
         completed after his father passed away.
         </p>

       </div>
       <h3>1964, Eero Saarinen</h3>
       <h3>850 Tipton Lane</h3>
       </div>

        </div>

        <div className="gallery">

          <div className="heading">
            <h1>Gallery</h1>
          </div>

              <div className="image-container">
                  <a className="chocolat-image" href={first} title="https://www.atlasofplaces.com/architecture/north-christian-church/?/North-Christian-Church-Eero-Saarinen-Associates">
                      <img src={first} />
                  </a>
                  <a className="chocolat-image" href={second} title="https://www.atlasofplaces.com/architecture/north-christian-church/?/North-Christian-Church-Eero-Saarinen-Associates">
                     <img src={second} />
                  </a>
                  <a className="chocolat-image" href={third} title="https://www.latimes.com/entertainment/arts/la-ca-cm-building-type-columbus-movie-20170803-htmlstory.html">
                     <img src={third} />
                  </a>
                  <a className="chocolat-image" href={fourth} title="https://www.orlandoweekly.com/orlando/columbus-is-a-worthy-destination/Content?oid=7299739">
                     <img src={fourth} />
                  </a>
                  <a className="chocolat-image" href={fifth} title="Columbus Screenshot">
                     <img src={fifth} />
                  </a>
                  <a className="chocolat-image" href={sixth} title="https://www.atlasofplaces.com/architecture/north-christian-church/?/North-Christian-Church-Eero-Saarinen-Associates">
                     <img src={sixth} />
                  </a>
              </div>

        </div>

        <About />

          <Footer />
          </div>
    )
  }
}

export default fifth_north_church;
