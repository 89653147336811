import React, {Component} from 'react'
import clip from './assets/videos/thirteenth_columbus_regional_hospital.mp4';
import first from './assets/images/thirteenth_columbus_regional_hospital1.jpg';
import second from './assets/images/thirteenth_columbus_regional_hospital2.jpg';
import third from './assets/images/thirteenth_columbus_regional_hospital3.png';
import fourth from './assets/images/thirteenth_columbus_regional_hospital4.png';
import Chocolat from 'chocolat';
import { Link, NavLink } from 'react-router-dom'
import About from "./assets/components/aboutsection";
import Footer from "./assets/components/footer";
import videoposter from './assets/images/videoposter.jpg';
import LazyLoad from "vanilla-lazyload";

function lightbox(){ 
document.addEventListener("DOMContentLoaded", function(event) {
        Chocolat(document.querySelectorAll('.chocolat-parent .chocolat-image'))
      })
}

lightbox();

const Loading = () => (
  <img src='./Columbus.jpg' />
)

class thirteenth_columbus_regional_hospital extends Component {
  render() {
    return (
      <div>

        <h1 className="main-title"><Link to="/">Columbus</Link></h1>
        <div className="wrapper">

       <video autoPlay loop muted className="wrapper__video" data-src={clip} poster={videoposter}>
          <source src={clip} type="video/mp4">
          </source>
       </video>

       <div className="page-content">
         <div className="content-wrapper">

         <h1 className="page-heading">Columbus Regional Hospital</h1>
         <p>Robert A. M. Stern
         spearheaded renovation of Columbus Regional Hospital (formerly Bartholomew County Hospital). The original
         1917 Italianate style building had been sporadically updated as time went on but Stern ensured
         the building was primed for technological advancements to happen at the turn of the millennium.
         <br />
         <br />
         This is the hosptial Jin's father resided and Casey's mother worked. 
         </p>

       </div>
       <h3>1995, Robert A. M. Stern</h3>
       <h3>2400 E. 17th Street</h3>
       </div>

        </div>

        <div className="gallery">

          <div className="heading">
            <h1>Gallery</h1>
          </div>

              <div className="image-container">
                  <a className="chocolat-image" href={first} title="First Christian Church">
                      <img src={first} />
                  </a>
                  <a className="chocolat-image" href={second} title="Columbus Screenshot">
                     <img src={second} />
                  </a>
                  <a className="chocolat-image" href={third} title="Columbus Screenshot">
                     <img src={third} />
                  </a>
                  <a className="chocolat-image" href={fourth} title="Columbus Screenshot">
                     <img src={fourth} />
                  </a>
              </div>

        </div>

          <About />
          <Footer />
          </div>
    )
  }
}

export default thirteenth_columbus_regional_hospital;
