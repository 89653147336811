import React, {Component} from 'react'
import clip from './assets/videos/seventh_covered_bridge.mp4';
import first from './assets/images/seventh_covered_bridge1.jpg';
import second from './assets/images/seventh_covered_bridge2.jpg';
import third from './assets/images/seventh_covered_bridge3.png';
import fourth from './assets/images/seventh_covered_bridge4.png';
import Chocolat from 'chocolat';
import { Link, NavLink } from 'react-router-dom'
import About from "./assets/components/aboutsection";
import Footer from "./assets/components/footer";
import videoposter from './assets/images/videoposter.jpg';
import LazyLoad from "vanilla-lazyload";

function lightbox(){ 
document.addEventListener("DOMContentLoaded", function(event) {
        Chocolat(document.querySelectorAll('.chocolat-parent .chocolat-image'))
      })
}

lightbox();


class seventh_covered_bridge extends Component {
  render() {
    return (
      <div>

        <h1 className="main-title"><Link to="/">Columbus</Link></h1>
        <div className="wrapper">

       <video autoPlay loop muted className="wrapper__video" data-src={clip} poster={videoposter}>
          <source src={clip} type="video/mp4">
          </source>
       </video>
       <div className="page-content">
         <div className="content-wrapper">

         <h1 className="page-heading">Covered Bridge and Pond at Mill Race Park</h1>
         <p>The bridge was originally built in 1840 by Adam Mason where it lay across East Fork of the White River Water.
         However, after sustaining damage it was dismantled and reassembled over the pond in in Mill Race Park.
         </p>

       </div>
       <h3>1840, Adam Mason</h3>
       <h3>50 Carl Miske Drive</h3>
       </div>

        </div>

        <div className="gallery">

          <div className="heading">
            <h1>Gallery</h1>
          </div>

              <div className="image-container">
                  <a className="chocolat-image" href={first} title="Mill Race Park - https://columbus.in.us/see-do/mill-race-park/?asset=3183-ig-2118677847840133246">
                      <img src={first} />
                  </a>
                  <a className="chocolat-image" href={second} title="Copyright by Steve Gass - https://pixels.com/featured/new-brownsville-covered-bridge-columbus-indiana-steve-gass.html">
                     <img src={second} />
                  </a>
                  <a className="chocolat-image" href={third} title="Columbus Screenshot">
                     <img src={third} />
                  </a>
                  <a className="chocolat-image" href={fourth} title="Columbus Screenshot">
                     <img src={fourth} />
                  </a>
              </div>

        </div>

        <About />

          <Footer />
          </div>
    )
  }
}

export default seventh_covered_bridge;
