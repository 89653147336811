import React, {Component} from 'react'
import clip from './assets/videos/eighteenth_friendship_way.mp4';
import first from './assets/images/eighteenth_friendship_way1.jpg';
import second from './assets/images/eighteenth_friendship_way2.png';
import Chocolat from 'chocolat';
import { Link, NavLink } from 'react-router-dom'
import About from "./assets/components/aboutsection";
import Footer from "./assets/components/footer";
import videoposter from './assets/images/videoposter.jpg';
import LazyLoad from "vanilla-lazyload";

function lightbox(){ 
document.addEventListener("DOMContentLoaded", function(event) {
        Chocolat(document.querySelectorAll('.chocolat-parent .chocolat-image'))
      })
}

lightbox();


class eighteenth_friendship_way extends Component {
  render() {
    return (
      <div>

        <h1 className="main-title"><Link to="/">Columbus</Link></h1>
        <div className="wrapper">

       <video autoPlay loop muted className="wrapper__video" data-src={clip} poster={videoposter}>
          <source src={clip} type="video/mp4">
          </source>
       </video>
       <div className="page-content">
         <div className="content-wrapper">

         <h1 className="page-heading">Friendship Way</h1>
         <p>

         Friendship Way is an alley way situated between Washington and Jackson Streets in Columbus.
         Citizens of Miyoshi, Aichi in Japan (Columbus' twin city) contributed $35,000 worth of bricks with their
         names engraved on the path.
         </p>

       </div>
       <h3>1998, William A. Johnson</h3>
       <h3 className="h3-long">Alley between 4th and 5th Streets on Washington Street</h3>
       </div>

        </div>

        <div className="gallery">

          <div className="heading">
            <h1>Gallery</h1>
          </div>

              <div className="image-container">
                  <a className="chocolat-image" href={first} title="Friendship Way - https://architectsandartisans.com/in-columbus-the-cathedral-builder/">
                      <img src={first} />
                  </a>
                  <a className="chocolat-image" href={second} title="Columbus Screenshot">
                     <img src={second} />
                  </a>
              </div>

        </div>

          <About />
          <Footer />
          </div>
    )
  }
}

export default eighteenth_friendship_way;
