import React from "react";
import { Link, NavLink } from 'react-router-dom'
import { motion } from 'framer-motion'
import columbusabout from '../../Columbus.jpg';

const transition = { duration: 0.9, ease: [0.17, 0.67, 0.83, 0.67] };

const About = () => {
  return (
   <div className="about-section">
        
          <div className="about-link">
            <Link className="link-about" to="/about"><h1>About</h1></Link>
            </div>
            <Link to="/about" replace>
            <motion.img 
              whileHover={{ scale: 0.9 }}
              src={columbusabout} 
              transition={transition}
              />
              </Link>
          </div>
  );
};

export default About;