import React, {Component} from 'react'
import clip from './assets/videos/eleventh_city_hall.mp4';
import first from './assets/images/eleventh_city_hall1.jpg';
import second from './assets/images/eleventh_city_hall2.jpg';
import third from './assets/images/eleventh_city_hall3.png';
import fourth from './assets/images/eleventh_city_hall4.png';
import Chocolat from 'chocolat';
import { Link, NavLink } from 'react-router-dom'
import About from "./assets/components/aboutsection";
import Footer from "./assets/components/footer";
import videoposter from './assets/images/videoposter.jpg';
import LazyLoad from "vanilla-lazyload";

function lightbox(){ 
document.addEventListener("DOMContentLoaded", function(event) {
        Chocolat(document.querySelectorAll('.chocolat-parent .chocolat-image'))
      })
}

lightbox();


class eleventh_city_hall extends Component {
  render() {
    return (
      <div>

        <h1 className="main-title"><Link to="/">Columbus</Link></h1>
        <div className="wrapper">

       <video autoPlay loop muted className="wrapper__video" data-src={clip} poster={videoposter}>
          <source src={clip} type="video/mp4">
          </source>
       </video>
       <div className="page-content">
         <div className="content-wrapper">

         <h1 className="page-heading">Columbus City Hall</h1>
         <p>
         Columbus needed to adapt to the city's rapid growth and need for a larger, more sophisticated
         City Hall. In 1981, Edward Charles Bassett (of Skidmore, Owings & Merrill) embarked on a bold
         venture in creating this 66,000 square foot modernist building. The courtyard is made from 
         glass, giving off a swanky office building look, while the remainder of the building is made from 
         brick and limestone. Casey ranked this building as "probably late teens, twenties" on her architecure list
         of Columbus.
         </p>

       </div>
       <h3>1981, Edward Charles Bassett</h3>
       <h3>123 Washington Street</h3>
       </div>

        </div>

        <div className="gallery">

          <div className="heading">
            <h1>Gallery</h1>
          </div>

              <div className="image-container">
                  <a className="chocolat-image" href={first} title="Photo by Pete Sieger - https://www.flickr.com/photos/peterjsieger/25539889700">
                      <img src={first} />
                  </a>
                  <a className="chocolat-image" href={second} title="Photo by Travis Stansel - https://www.flickr.com/photos/59012244@N06/42361389950">
                     <img src={second} />
                  </a>
                  <a className="chocolat-image" href={third} title="Columbus Screenshot">
                     <img src={third} />
                  </a>
                  <a className="chocolat-image" href={fourth} title="Columbus Screenshot">
                     <img src={fourth} />
                  </a>
              </div>

        </div>

          <About />

          <Footer />
          </div>
    )
  }
}

export default eleventh_city_hall;
