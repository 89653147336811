import React, {Component} from 'react'
import clip from './assets/videos/fifteenth_cleo_rogers_memorial_library.mp4';
import first from './assets/images/fifteenth_cleo_rogers_memorial_library1.jpg';
import second from './assets/images/fifteenth_cleo_rogers_memorial_library2.jpg';
import third from './assets/images/fifteenth_cleo_rogers_memorial_library3.jpg';
import fourth from './assets/images/fifteenth_cleo_rogers_memorial_library4.jpg';
import fifth from './assets/images/fifteenth_cleo_rogers_memorial_library5.png';
import sixth from './assets/images/fifteenth_cleo_rogers_memorial_library6.png';
import seventh from './assets/images/fifteenth_cleo_rogers_memorial_library7.png';
import eighth from './assets/images/fifteenth_cleo_rogers_memorial_library8.png'
import Chocolat from 'chocolat';
import { Link, NavLink } from 'react-router-dom'
import About from "./assets/components/aboutsection";
import Footer from "./assets/components/footer";
import videoposter from './assets/images/videoposter.jpg';
import LazyLoad from "vanilla-lazyload";

function lightbox(){ 
document.addEventListener("DOMContentLoaded", function(event) {
        Chocolat(document.querySelectorAll('.chocolat-parent .chocolat-image'))
      })
}

lightbox();


class fifteenth_cleo_rogers_memorial_library extends Component {
  render() {
    return (
      <div>

        <h1 className="main-title"><Link to="/">Columbus</Link></h1>
        <div className="wrapper">

       <video autoPlay loop muted className="wrapper__video" data-src={clip} poster={videoposter}>
          <source src={clip} type="video/mp4">
          </source>
       </video>
       <div className="page-content">
         <div className="content-wrapper">

         <h1 className="page-heading">Cleo Rogers Memorial Library</h1>
         <p>

         Cleo Rogers Memorial Library was designed to be the first civic space in downtown Columbus. Opposite lies 
         the First Christian Church while to the right lies the Irwin House. These are arguably the
         three most important buildings in Columbus. A historic Henry Moore bronze statue was introduced
         in the square to give to tie all the surroundings together and create the first real urban space in 
         Columbus.
         </p>

       </div>
       <h3>1969, I.M. Pei</h3>
       <h3>536 5th Street</h3>
       </div>

        </div>

        <div className="gallery">

          <div className="heading">
            <h1>Gallery</h1>
          </div>

              <div className="image-container">
                  <a className="chocolat-image" href={first} title="Cleo Rogers Memorial Library - http://joshuamings.com/newsite/2017/03/11/cleo-rogers-memorial-library-i-m-pei/">
                      <img src={first} />
                  </a>
                  <a className="chocolat-image" href={second} title="Cleo Rogers Memorial Library - http://joshuamings.com/newsite/2017/03/11/cleo-rogers-memorial-library-i-m-pei/">
                     <img src={second} />
                  </a>
                  <a className="chocolat-image" href={third} title="Cleo Rogers Memorial Library - http://joshuamings.com/newsite/2017/03/11/cleo-rogers-memorial-library-i-m-pei/">
                     <img src={third} />
                  </a>
                  <a className="chocolat-image" href={fourth} title="Cleo Rogers Memorial Library - https://en.wikipedia.org/wiki/Cleo_Rogers_Memorial_Library#/media/File:CLEO_ROGERS_MEMORIAL_LIBRARY_-_NARA_-_546493.tif">
                     <img src={fourth} />
                  </a>
                  <a className="chocolat-image" href={fifth} title="Columbus Screenshot">
                      <img src={fifth} />
                  </a>
                  <a className="chocolat-image" href={sixth} title="Columbus Screenshot">
                     <img src={sixth} />
                  </a>
                  <a className="chocolat-image" href={seventh} title="Columbus Screenshot">
                     <img src={seventh} />
                  </a>
                  <a className="chocolat-image" href={eighth} title="Columbus Screenshot">
                     <img src={eighth} />
                  </a>
              </div>

        </div>

          <About />
          <Footer />
          </div>
    )
  }
}

export default fifteenth_cleo_rogers_memorial_library;
