import React from "react";
import { Link, NavLink } from 'react-router-dom'
import website from '../icons/website.png';
import email from '../icons/email.png';
import twitter from '../icons/twitter.png';
import instagram from '../icons/instagram.png';

const Footer = () => {
  return (
  
  <div className="footer footer-heading">
            <nav className="socials">
              <a href="http://dinvilliers.com/">© 2020 Wolfgang - All Rights Reserved</a>
            </nav>
</div>

  );
};

export default Footer;