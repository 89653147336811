import React, {Component} from 'react'
import clip from './assets/videos/sixteenth_viewpoint_bookstore.mp4';
import first from './assets/images/sixteenth_viewpoint_bookstore1.JPG';
import second from './assets/images/sixteenth_viewpoint_bookstore2.png';
import third from './assets/images/sixteenth_viewpoint_bookstore3.png';
import fourth from './assets/images/sixteenth_viewpoint_bookstore4.png';
import Chocolat from 'chocolat';
import { Link, NavLink } from 'react-router-dom'
import About from "./assets/components/aboutsection";
import Footer from "./assets/components/footer";
import videoposter from './assets/images/videoposter.jpg';
import LazyLoad from "vanilla-lazyload";

function lightbox(){ 
document.addEventListener("DOMContentLoaded", function(event) {
        Chocolat(document.querySelectorAll('.chocolat-parent .chocolat-image'))
      })
}

lightbox();


class sixteenth_viewpoint_bookstore extends Component {
  render() {
    return (
      <div>

        <h1 className="main-title"><Link to="/">Columbus</Link></h1>
        <div className="wrapper">

       <video autoPlay loop muted className="wrapper__video" data-src={clip} poster={videoposter}>
          <source src={clip} type="video/mp4">
          </source>
       </video>
       <div className="page-content">
         <div className="content-wrapper">

         <h1 className="page-heading">Viewpoint Books</h1>
         <p>

         Built in 1973, Viewpoint Books is still the second oldest independent
        local bookstore in Indiana. This store served as the interior for “Duncan Game Shop” 
        which Jin briefly entered.
         </p>

       </div>
       <h3>1973, Charles F. Sparrell</h3>
       <h3>548 Washington St</h3>
       </div>

        </div>

        <div className="gallery">

          <div className="heading">
            <h1>Gallery</h1>
          </div>

              <div className="image-container">
                  <a className="chocolat-image" href={first} title="Viewpoint Books - http://theindiebobspot.blogspot.com/2016/12/viewpoint-books-columbus-in.html">
                      <img src={first} />
                  </a>
                  <a className="chocolat-image" href={second} title="Columbus Screenshot">
                     <img src={second} />
                  </a>
                  <a className="chocolat-image" href={third} title="Columbus Screenshot">
                     <img src={third} />
                  </a>
                  <a className="chocolat-image" href={fourth} title="Columbus Screenshot">
                     <img src={fourth} />
                  </a>
              </div>

        </div>

          <About />
          <Footer />
          </div>
    )
  }
}

export default sixteenth_viewpoint_bookstore;
