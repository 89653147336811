import React, { Component } from 'react';
import {
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom";
import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import ReactDOM from 'react-dom'
import About from './About'
import FirstChristianChurch from './FirstChristianChurch'
import second_irwin_conference_center from './second_irwin_conference_center'
import third_quinco_mental from './third_quinco_mental'
import fourth_first_bank from './fourth_first_bank'
import fifth_north_church from './fifth_north_church'
import sixth_lookout_tower from './sixth_lookout_tower'
import seventh_covered_bridge from './seventh_covered_bridge'
import eighth_veterans_memorial from './eighth_veterans_memorial'
import ninth_newpaper_plant from './ninth_newpaper_plant'
import tenth_southside_school from './tenth_southside_school'
import eleventh_city_hall from './eleventh_city_hall'
import twelveth_miller_house from './twelveth_miller_house'
import thirteenth_columbus_regional_hospital from './thirteenth_columbus_regional_hospital'
import fourteenth_the_inn from './fourteenth_the_inn'
import fifteenth_cleo_rogers_memorial_library from './fifteenth_cleo_rogers_memorial_library'
import sixteenth_viewpoint_bookstore from './sixteenth_viewpoint_bookstore'
import seventeenth_henry_social_club from './seventeenth_henry_social_club'
import eighteenth_friendship_way from './eighteenth_friendship_way'
import nineteenth_robert_n_stewart_bridge from './nineteenth_robert_n_stewart_bridge'
import Maps from './Maps'
import { AnimatePresence, motion } from "framer-motion";
import './index.css'

function App() {
    return (
        <BrowserRouter>
        <Route render={({ location }) => ( console.log(location) || 
        <AnimatePresence initial={true} exitBeforeEnter>
        <motion.div 
          initial={{ opacity: 0}}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.6 }}
        >
            <Switch location={location} key={location.pathname}>
                <Route exact path="/" component={Maps}/>
                <Route path="/about" component={About} />
                <Route path="/first-christian-church" component={FirstChristianChurch} />
                <Route path="/columbus-regional-hospital" component={thirteenth_columbus_regional_hospital} />
                <Route path="/irwin-conference-center" component={second_irwin_conference_center} />
                <Route path="/quinco-mental" component={third_quinco_mental} />
                <Route path="/first-financial-bank" component={fourth_first_bank} />
                <Route path="/north-christian-church" component={fifth_north_church} />
                <Route path="/mill-race-park-observation-tower" component={sixth_lookout_tower} />
                <Route path="/covered-bridge-and-pond" component={seventh_covered_bridge} />
                <Route path="/veterans-memorial" component={eighth_veterans_memorial} />
                <Route path="/newspaper-plant" component={ninth_newpaper_plant} />
                <Route path="/southside-elementary-school" component={tenth_southside_school} />
                <Route path="/columbus-city-hall" component={eleventh_city_hall} />
                <Route path="/miller-house" component={twelveth_miller_house} />
                <Route path="/the-inn" component={fourteenth_the_inn} />
                <Route path="/cleo-rogers-memorial-library" component={fifteenth_cleo_rogers_memorial_library} />
                <Route path="/viewpoint-bookstore" component={sixteenth_viewpoint_bookstore} />
                <Route path="/henry-social-club" component={seventeenth_henry_social_club} />
                <Route path="/friendship-way" component={eighteenth_friendship_way} />
                <Route path="/robert-n-stewart-bridge" component={nineteenth_robert_n_stewart_bridge} />
            </Switch>
            </motion.div>
            </AnimatePresence>
            )}
        
        />
        </BrowserRouter>
    )
}

export default App;