import React, {Component} from 'react'
import clip from './assets/videos/eighth_veterans_memorial.mp4';
import first from './assets/images/eighth_veterans_memorial1.jpg';
import second from './assets/images/eighth_veterans_memorial2.jpg';
import third from './assets/images/eighth_veterans_memorial3.jpg';
import fourth from './assets/images/eighth_veterans_memorial4.jpg';
import Chocolat from 'chocolat';
import { Link, NavLink } from 'react-router-dom'
import About from "./assets/components/aboutsection";
import Footer from "./assets/components/footer";
import videoposter from './assets/images/videoposter.jpg';
import LazyLoad from "vanilla-lazyload";

function lightbox(){ 
document.addEventListener("DOMContentLoaded", function(event) {
        Chocolat(document.querySelectorAll('.chocolat-parent .chocolat-image'))
      })
}

lightbox();


class eighth_veterans_memorial extends Component {
  render() {
    return (
      <div>

        <h1 className="main-title"><Link to="/">Columbus</Link></h1>
        <div className="wrapper">

       <video autoPlay loop muted className="wrapper__video" data-src={clip} poster={videoposter}>
          <source src={clip} type="video/mp4">
          </source>
       </video>
       <div className="page-content">
         <div className="content-wrapper">

         <h1 className="page-heading">Bartholomew County Veterans Memorial</h1>
        <p> This stunning memorial display consists of 25 limsetone pillars in rows of 5, 
         standing 40 feet tall and 2.5 square feet. The pillars are engraved with the names
         of the soliders who lost their lives in the wars of the 20th century.
         The landscaping in this memorial was done by Michael Van Valkenburgh.
         </p>

       </div>
       <h3>1997, Thompson and Rose</h3>
       <h3>234 Washington Street</h3>
       </div>

        </div>

        <div className="gallery">

          <div className="heading">
            <h1>Gallery</h1>
          </div>

              <div className="image-container">
                  <a className="chocolat-image" href={second} title="Photos from Charlie Rose Architects - https://www.charlesrosearchitects.com/projects/veterans-memorial/">
                     <img src={second} />
                  </a>
                  <a className="chocolat-image" href={third} title="Photos from Charlie Rose Architects - https://www.charlesrosearchitects.com/projects/veterans-memorial/">
                     <img src={third} />
                  </a>
                  <a className="chocolat-image" href={fourth} title="Photos from Charlie Rose Architects - https://www.charlesrosearchitects.com/projects/veterans-memorial/">
                     <img src={fourth} />
                  </a>
                  <a className="chocolat-image" href={first} title="Photos from Charlie Rose Architects - https://www.charlesrosearchitects.com/projects/veterans-memorial/">
                      <img src={first} />
                  </a>
              </div>

        </div>

        <About />

          <Footer />
          </div>
    )
  }
}

export default eighth_veterans_memorial;
